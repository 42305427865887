.wrapper {
    display: flex;
    align-items: end;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    position: relative;
}

.map {
    width: 100%;
    height: 75%;
}

.popup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: start;
    position: absolute;
    z-index: 9999;
}

.popupList {
    padding: .5rem .8rem;
    border: 2px solid #fff;
    background-color: rgba(42, 116, 151, 0.8);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
}

.popupListWrap {
    z-index: 111;
    background-color: rgba(0, 0, 0, .1);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: start;
    justify-content: center;
}

.popupListTitle {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
    padding: .5rem 0;    
}

.popupList b {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    padding: .5rem 0;
    border-bottom: 2px solid #fff;
    height: 39px;
    display: inline-block;
    margin-left: 6rem;
}

.popupList span {
    cursor: pointer;
    color: #fff;
    font-size: 25px;
    font-weight: 400;
    padding: .5rem 0;
    transition: color .2s ease-in-out;
}

.popupList span:hover {
    color: rgb(209, 209, 209);
}

.map_tooltip {
    background-color: red;
    display: block;
}

.popupInner {
    background-color: #fff;
    width: 60%;
    height: 80%;
    border-radius: 10px;
    padding: 2rem 3rem;
}

.popupListWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    width: 100%;
    max-height: 70vh;
}

.popupListContent {
    height: 70vh;
}

.emblem_wrap {
    display: flex;
    align-items: center;
    justify-content: start;
}

.emblem {
    width: 100px;
    height: 123px;
    margin-right: 1rem;
    display: block;
}

.counter {
    position: absolute;
    left: 40px;
    top: 5rem;
    z-index: 99;
    font-size: 2rem;
    color: #fff;
}

.counter div {
    margin-bottom: 2rem;
}

.counter span {
    font-size: 2rem;
    font-weight: 600;
}

.counter_svo {
    position: absolute;
    right: 40px;
    top: 5rem;
    z-index: 10000;
    font-size: 1.5rem;
    color: #fff;
}

.svo {
    background-color: #fff;
    color: #3b618d;
    padding: .5rem 2rem;
    width: 240px;
    border-radius: .5rem;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    margin: 0 0 0 auto;
}

.profession {
    background-color: #fff;
    color: #3b618d;
    padding: .5rem 2rem;
    margin-top: 1rem;
    border-radius: .5rem;
    cursor: pointer;
    font-weight: 600;
    width: 240px;
}

.scheme {
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .5);
}

.scheme img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    color: #fff;
    font-size: 40px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3b618d;
    border-radius: 100px;
    border: 3px solid #fff;
    z-index: 99999999;
}