.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.title {
    color: #012c4e;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 1rem;
    width: 90%;
}

.row {
    color: #012c4e;
    font-size: 20px;
    font-weight: 400;
    margin-left: 15%;
    position: relative;
    margin-bottom: 5px;
    width: 68%;
}

.row span {
    font-weight: 600;
    position: absolute;
    left: -120px;
    text-align: right;
    width: 100px;
}

.docTitle {
    color: #012c4e;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

.docWrapper {
    display: flex;
    flex-direction: column;
}

.dosItem {
    color: #012c4e;
    font-size: 16px;
}

.emblem {
    width: 100px;
    position: absolute;
    right: -1rem;
    top: -1rem;
}

.email {
    position: absolute;
    bottom: -1.5rem;
    right: -2rem;
    cursor: pointer;
}

.back {
    position: absolute;
    top: -3rem;
    left: -1rem;
    cursor: pointer;
    width: fit-content;
    padding: .3rem 1.2rem;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    background-color: #e22c2c;
    transition: .3s;
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px rgba(0,0,0,.4);
}

.imgsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.imgsItem {
    height: 19vh;
    display: block;
    width: 100px;
}

.button {
    display: block;
    cursor: pointer;
    width: 150px;
    position: absolute;
    z-index: 999;
    right: -1.5rem;
    top: 23%;
}