.wrapper {
    width: 100%;
    border-bottom: solid 1px rgba(#000000, 0.2);
    box-shadow: 0 0 5px rgba(#000000, 0.2);
    padding: .5rem 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: relative;
}

.minTitle {
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: -1px;
    padding-top: 1rem;
    font-stretch: condensed;
}

.logo_lms {
    height: 100px;
}

.wrapper_mo {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.title {
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: -1px;
    font-stretch: condensed;
    text-align: center;
    padding: 20px;
    position: relative;
    color: #fff;
    width: 60%;
}

.title span {
    position: relative;
    z-index: 2;
}

.header_bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    z-index: 0;
    height: 100%;
}