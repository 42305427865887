// .wrapper {
//     // background-color: aquamarine;
//     padding: 2rem 1rem;
//     display: flex;
//     align-items: center;
//     z-index: 2;
//     position: relative;
//     width: fit-content;
// }

// .text {
//     font-size: 20px;
// }

// .textarea {
//     width: 30vw;
//     height: 20vh;
//     min-width: 300px;
//     // margin: .5rem;
//     font-size: 20px;
//     padding: .5rem 1rem;
//     resize: none;
//     overflow: auto;
//     background-color: #fff;
//     border: none;
//     border-radius: 5px 0 0 5px;
//     box-shadow: 0 0 20px rgba(167, 167, 167, 0.5);
// }


// @import url(https://fonts.googleapis.com/css?family=Fjalla+One);

// $red: rgb(232, 4, 21);
// $white: #222;
// $grey: rgb(162, 162, 162);
// $cont-size: 170px;
// $outer-size: $cont-size - 40;
// $inner-size: $outer-size - 30;
// $border-size: 6px;
// $sec: 1s;
// $bezier: cubic-bezier(0.4, -0.9, 0.9, 1);

// @mixin animation($name) {
//     @-webkit-keyframes #{$name} {
//         @content;
//     }
//     @-moz-keyframes #{$name} {
//         @content;
//     }
//     @-o-keyframes #{$name} {
//         @content;
//     }
//     @keyframes #{$name} {
//         @content;
//     }
// }

// @mixin animation-use($name, $time, $easing) {
//     -webkit-animation: $name $time infinite $easing;
//     -moz-animation: $name $time infinite $easing;
//     -o-animation: $name $time infinite $easing;
//     animation: $name $time infinite $easing;
// }

// @mixin animate($val, $colon) {
//     @include animation(to_ + $val) {
//         @for $i from 1 to $val {
//             #{100/$val*$i}% {
//                 @if ($i < 10) {
//                     content: $colon + "0" + $i;
//                 } @else {
//                     content: $colon + "" + $i;
//                 }
//             }
//         }
//     }
// }

// @mixin pseudo($content) {
//     position: relative;
//     content: $content;
// }

// @mixin center($val) {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     width: $val;
//     height: $val;
//     margin: -$val/2 0 -$val/2;
// }

// @mixin delay($time) {
//     -webkit-animation-delay: $time;
//     animation-delay: $time;
// }

// @mixin once() {
//     -webkit-animation-iteration-count: 1;
//     animation-iteration-count: 1;
//     -webkit-animation-fill-mode: forwards;
//     animation-fill-mode: forwards;
// }

// @include animate(24, "");
// @include animate(60, "");
// @include animate(100, ":");

// @include animation(time) {
//     to {
//         top: 100%;
//         opacity: 1;
//     }
// }

// @include animation(stop) {
//     70% {
//         border-radius: 6px;
//         @include center($inner-size - 40);
//     }
//     100% {
//         border-radius: 6px;
//         @include center($inner-size - 36);
//     }
// }


// .recorder_container {
//     // @include center($cont-size);
    
//     position: relative;
//     // width: $cont-size;
//     height: $cont-size;
//     zoom: .5;

//     .btn {
//         display: none;

//         & + label:before {
//             @include center($outer-size);
//             content: "";
//             -webkit-transform: translate(-$border-size, -$border-size);
//                 -ms-transform: translate(-$border-size, -$border-size);
//                     transform: translate(-$border-size, -$border-size);
//                         border-radius: 50%;
//                         border: $border-size solid $white;
//                         cursor: pointer;
//                         }

//         & + label:after {
//             @include center($inner-size);
//             content: "";
//             border-radius: $inner-size/2;
//             background: $red;
//             cursor: pointer;
//         }

//         &:checked {
//             & + label:after {
//                 @include animation-use(stop, 0.5*$sec, $bezier);
//                 @include once();
//             }

//             & ~ .time {
//                 @include animation-use(time, 0.3*$sec, linear);
//                 @include once();
//                 animation-delay: 0.3*$sec;
//             }

//             & ~ .time .h_m:after {
//                 @include animation-use(to_24, 86400*$sec, linear);
//                 @include delay(1800*$sec);
//             }

//             & ~ .time .h_m:before {
//                 @include animation-use(to_60, 3600*$sec, linear);
//                 @include delay(30*$sec);
//             }

//             & ~ .time .s_ms:before {
//                 @include animation-use(to_60, 60*$sec, linear);
//                 @include delay(0.5*$sec);
//             }

//             & ~ .time .s_ms:after {
//                 @include animation-use(to_100, $sec, linear);
//             }
//         }
//     }

//     .time {
//         position: absolute;
//         width: 100%;
//         text-align: center;
//         top: 110%;
//         opacity: 0;

//         & > * {
//             display: inline-block;
//             text-align: center;
//             margin: 0 0 0 -3rem;
//             letter-spacing: .2em;
//             color: $grey;
//             width: 120px;
//             font-family: "Fjalla One", sans-serif;
//             font-size: 2em;
            
//         }

//         .s_ms:before {
//             float: left;
//             @include pseudo("00 ");
//         }

//         .s_ms:after {
//             float: left;
//             @include pseudo("00");
//         }
//     }
// }

// .buttons_block {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     height: 20vh;
//     background-color: #fff;
//     border-radius: 0 5px 5px 0;
//     overflow: hidden;
// }

// .button {
//     font-size: 20px;
//     padding: .5rem 1rem;
//     border: none;
//     background: #26b2fd;
//     border-radius: 5px 0 0 0;
//     color: #fff;
//     cursor: pointer;
// }

// .wrapper {
//     z-index: 1;
//     position: relative;
//     background-color: #fff;
// }

.wrapper {
    position: relative;
    z-index: 9999999;
}

.container {
    position: absolute;
    right: .5rem;
    top: 0;
}

.container textarea {
    padding: .5rem 1rem;
    font-size: 20px;
    border: 2px solid #006eff;
    resize: none;
    width: 300px;
    height: 100px;
    outline: none;
    color: #fff;
    border-radius: 4px;
    background-color: #006eff;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.button {
    font-size: 20px;
    padding: 1rem 2rem;
    border: none;
    background: #006eff;
    border-radius: .6rem;
    color: #fff;
    cursor: pointer;
} 

.loader {
    position: relative;
    z-index: 999999;
    width: 60px;
    height: 60px;
} 