.drawing_app {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 1rem;
    width: 500px;
  }
  
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 1rem 0;
    background-color: #dedede;
    padding: 0 1rem;
    border-radius: 10px;
  }
  
  .control_group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem .5rem;
    font-size: 1.2rem;
}

.control_group label {
    margin-bottom: 1rem;
}

  .brush_radius_controls {
    display: flex;
    flex-direction: row;
  }

  input[type=color]{
	width: 40px;
	height: 40px;
	border: none;
	border-radius: 40px;
	background: none;
}
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
        border: solid 1px #000;
        border-radius: 40px;
    }

  .buttonBrush {
    background-color: rgb(181, 181, 181);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 40px;
    cursor: pointer;
    border-radius: 100px;
    user-select: none;
  }

  .brush_radius_value {
    font-size: 20px;
    width: 40px;
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eraser {
    background-color: rgb(181, 181, 181);
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  .eraser_icon {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }

  .trash_icon {
    font-size: 40px;
    width: 40px;
    height: 40px;
    color: #ababab;
    cursor: pointer;
  }

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttons {
    display: flex;
    margin-right: 1rem;
  }

  .button_save {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    margin: 0 0 0 1rem;
  }
  
  .button_delete {
    padding: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    margin: 0 1rem;
  }
 
  .button_clear {
    padding: 10px;
    background-color: grey;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    margin: 0 1rem;
  }
  
  .button:hover {
    background-color: #45a049;
  }

  .save_button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    margin: .5rem auto;
  }