@import-normalize;

@font-face {
	font-family: 'FuturaFuturisLigthC';
	src: url('./assets/fonts/FuturaFuturisLightC.eot');
	src: local('./assets/fonts/FuturaFuturisLightC'),
		url('./assets/fonts/FuturaFuturisLightC.woff') format('woff'),
		url('./assets/fonts/FuturaFuturisLightC.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none !important;
}

body::-webkit-scrollbar, body div::-webkit-scrollbar {
  background-color: rgba(238, 238, 238, 0.562);
  width: .2rem;
  height: .2rem;
}

body::-webkit-scrollbar-thumb, body div::-webkit-scrollbar-thumb {
  background-color: #99dfff;
  border-radius: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: FuturaFuturisLigthC;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999999999999 !important;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}