.wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);
}

.inner {
    position: relative;
    width: 90%;
    height: 90vh;
    overflow: hidden;
    /* background-color: antiquewhite; */
}

.bg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
}

.content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999999999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem 1rem 1rem;
}

.header {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.guk {
    width: 100px;
    height: 100px;
}

.title {
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 900;
    text-align: center;
}   

.wrapperPhoto {
    text-align: center;
}

.photoTitle {
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    /* margin-top: 3rem; */

}

.photo {
    width: 20px;
    margin-left: 1rem;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 1rem;
}

.footer_start {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    margin: 0 2rem 2rem 2rem;
}

.footer_start div {
    border-bottom: 2px solid #fff;
}

.footer_star {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.star {
    width: 80px;
    cursor: pointer;
}

.home {
    width: 80px;
    cursor: pointer;
}

.back {
    width: 80px;
    cursor: pointer;
}

.photoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.photo_man {
    margin-top: 2rem;
    width: 200px;
    height: 200px;
    background-color: antiquewhite;
    border: 2px solid grey;
    border-radius: 2px;
}

.photo_big {
    width: 80px;
    margin: 2rem 0 0 0;   
}

.footer_start_next {
    cursor: pointer;
    font-size: 3rem;
}



/* 2 */

.wrapperAge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    width: 100%;
    padding: 0 2rem;
}

.ageTitle {
    font-size: 25px;
    font-weight: 900;
    /* text-align: center; */
    margin-bottom: 1rem;
}

.age {
    display: flex;
    justify-content: start;
    width: 100%;
    margin: 0 auto;
}

.age span{
    color: #99dfff;
    font-size: 150px;
    margin-right: 2rem;
    font-weight: 900;
    user-select: none;
}

.arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.arrowTop {
    height: 80px;
    font-size: 80px;
    cursor: pointer;
}

.arrowBottom {
    height: 80px;
    font-size: 80px;
    transform: rotate(180deg);
    cursor: pointer;
}

.sex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.active_sex {
    color: #99dfff;
    font-weight: 900;
    margin: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    width: 50%;
    justify-content: center;
    padding: 1rem;
    border: 2px solid #fff;
}

.done {
    position: absolute;
    font-size: 50px;
    left: 100px;
    top: 40px;
}

.sex_block:hover, .active_sex:hover {
    background-color: #3b618d;
}

.sex_block {
    border: 2px solid #fff;
    transition: .5s;
    justify-content: center;
    width: 50%;
    color: #fff;
    font-weight: 900;
    margin: 1rem;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.sex span {
    font-size: 50px;
    font-weight: 900;
    margin-right: 2rem;
}

.sex img {
    width: auto;
    height: 100px;
}

.sex div {
    cursor: pointer;
}

/* 2 */

/* 3 */

.spec {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    overflow: auto;
}

.specItem {
    font-size: 25px;
    margin: .5rem 0;
    color: #fff;
    width: 40%;
    cursor: pointer;
    transition: .5s;
}

.specItem:hover {
    color: #99dfff;
}

.position_image {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: absolute;
    right: 2rem;
    top: 1rem;
    width: 200px;
    padding-bottom: 2rem;
}

.boy {
    z-index: 1;
    width: 100%;
    object-fit: contain;
}

.positionIcon {
    z-index: 2;
    width: 150px;
    position: absolute;
    bottom: 0;
}

/* 3 */


/* final */

.result {
    border-bottom: 2px solid #fff;
}

.text {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
}

.text span {
    color: #99dfff;
}

.text_decr {
    margin-bottom: 1.5rem;
    color: #fff;
    font-size: 1.3rem;
}

.imgs {
    margin: .5rem;
    display: flex;
    justify-content: space-between;
}

.text_vuz {
    margin-bottom: 1.5rem;
}

.img {
    width: 18%;
}

.emailSend {
    margin: 0 auto;
    width: fit-content;
    font-weight: 900;
    font-size: 1.5rem;
    bottom: 3rem;
    background: #3b618d;
    color: #fff;
    border: 2px solid #fff;
    padding: .4rem 1rem;
    border-radius: 100px;
    margin-top: -2rem;
    cursor: pointer;
}

/* final */

/* test */

.question {
    margin: 1rem;
}

.question_title {
    font-size: 30px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 2rem;
}

.question_answer {
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    padding: .5rem 0;
    cursor: pointer;
}

/* test */

.emblema {
    width: 150px;
    height: 150px;
}

.emblema img {
    width: 100%;
    height: 100%;
}

.vuzBlock {
    display: flex;
    align-items: center;
}

.vuzBlock span {
    margin: 0 2rem;
}