.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #e4e4e4;
    position: relative;
}

.myVideo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 0;
}

.form {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(113, 113, 113, .8);
    z-index: 9999999;
}

.close {
    background-color: #3b618d;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 40px;
    border-radius: 100px;
    cursor: pointer;
    border: 3px solid #fff;
}

.input {
    border: 3px solid #3b618d;
    background-color: #fff;
    height: 50px;
    font-size: 25px;
    outline: none;
    border-radius: 30px;
    text-align: center; 
    width: 560px;
    margin: 0 1rem;
}

.send {
    border: 3px solid #fff;
    background-color: #3b618d;
    padding: 12px 20px;
    color: #fff;
    border-radius: 50px;
    font-size: 20px;
    cursor: pointer;
}

.formInner {
    display: flex;
    margin-bottom: 1rem;
    width: 800px;
    justify-content: space-between;
}