.form {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(113, 113, 113, .7);
    z-index: 9999999;
}

.close {
    background-color: #3b618d;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 40px;
    border-radius: 100px;
    cursor: pointer;
    border: 3px solid #fff;
    margin: 0 0 0 auto;
}

.close_popup {
    background-color: #3b618d;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    position: absolute;
    height: 50px;
    font-size: 40px;
    right: -4rem;
    top: -3rem;
    border-radius: 100px;
    cursor: pointer;
    border: 3px solid #fff;
    margin: 0 0 0 auto;
}

.input {
    border: 3px solid #3b618d;
    background-color: #fff;
    height: 50px;
    font-size: 25px;
    outline: none;
    border-radius: 30px;
    text-align: center; 
    width: 300px;
    margin: 0 1rem;
}

.send {
    display: flex;
    align-items: center;
    border: 3px solid #fff;
    background-color: #3b618d;
    padding: 12px 20px;
    color: #fff;
    border-radius: 50px;
    font-size: 30px;
    cursor: pointer;
    margin: 1rem 0 0 auto;
    width: fit-content;
    transition: .5s;
    user-select: none;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.draw {
    display: flex;
    align-items: center;
    border: 3px solid #fff;
    background-color: #3b618d;
    padding: 12px 20px;
    color: #fff;
    border-radius: 50px;
    font-size: 30px;
    cursor: pointer;
    width: fit-content;
    transition: .5s;
    user-select: none;
    margin: 1rem 0 0 0;
}

.popupDraw {
    z-index: 999999999;
    position: fixed;
    left: 2rem;
    right: auto;
    top: 2rem;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5rem;
}

.loader {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: .5rem;
}

.formInner {
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 800px;
}

.subject {
    display: block;
    width: 100%;
    margin: 1rem 0;
    font-size: 25px;
    outline: none;
    padding: .5rem 1rem;
}

.signatureTitle {
    font-size: 35px;
    margin-bottom: 1rem;
    color: #fff;
}

.signaturies {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 1rem 0;
    font-size: 25px;
    outline: none;
    height: 100px;
    padding: .5rem 1rem;
    resize: none;
}

.body {
    resize: none;
    display: block;
    width: 100%;
    margin: 1rem 0;
    font-size: 25px;
    outline: none;
    padding: .5rem 1rem;
    height: 250px;
}

.Ouseau {
    position: absolute;
    right: -53%;
    top: 25%;
}

.sold {
    font-size: 50px;
    color: #fff;
    border-bottom: 5px solid #fff;
    display: inline-block;
    font-style: italic;
}

.popup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999999999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(113, 113, 113, .9);
    transition: .5rem;
}

.popup__content {
    position: relative;
    font-size: 30px;
    width: 40vw;
    text-align: center;
    background-color: #3b618d;
    color: #fff;
    padding: 2rem;
    transition: .5s;
    user-select: none;
}