.location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, .7);

}

.location_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
}

.location_title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #012c4e;
}

.location_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    padding: .5rem;
    border-radius: 10px;
    border: 3px solid #012c4e;
    outline: none;
    font-size: 30px;
    text-align: center;
}

.location_send {
    display: flex;
    align-items: center;
    border: 3px solid #fff;
    background-color: #3b618d;
    padding: 12px 20px;
    color: #fff;
    border-radius: 50px;
    font-size: 30px;
    cursor: pointer;
    margin: 1rem 0 0 auto;
    width: -moz-fit-content;
    width: fit-content;
    transition: .5s;
    user-select: none;
}